/* -------------------- General Site layouts -------------------- **/
* {
  font-family: $font-stack;
  color: $textDark;
  text-decoration: none !important;
  outline: none !important;
  -webkit-font-smoothing: antialiased;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

img {
  max-width: 100%;
}

a {
  color: #f59439;
}

/*** Page ***/
.PageResults,
.InnerPage {
  .banner {
    height: 100vh;
  }
}

.vacancy {
  padding: 30px 0px;
  border-bottom: 2px solid #eee;
  display: block;
  float: left;
  width: 100%;
}

.CalendarFilter {
  padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li {
  /* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span {
  cursor: pointer;
  display: inline-block;
  padding: 5px;
  border-bottom: 2px solid transparent;
  margin-bottom: 10px;
}
.CalendarFilter span.active {
  border-bottom: 2px solid $themeColor !important;
  color: $themeColor !important;
}
.noEvents {
  display: none;
  padding: 20px 10px;
  color: #666;
  @include border-radius(3px);
  background-color: #eee;
}

#allEvents ul {
  max-width: 500px;
  margin: 0px !important;
  padding: 0px !important;
}

#allEvents ul li {
  margin-bottom: 10px;
  background-color: $themeColor;
  float: left;
  width: 100%;
  clear: both;
  @include transition(background 0.35s);
  display: block;
}

.toggleContent {
  display: none;
  font-size: 0.8em;
  * {
    font-size: 1em;
    color: #333 !important;
  }
  .url {
    padding: 10px;
    color: #fff !important;
    background-color: $themeColor;
    @include transition($t1);
    @include border-radius(3px);
    /*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
  }
}

.eventTitle {
  color: #333 !important;
  padding: 10px;
  text-transform: uppercase;
}
.toggleBtn {
  cursor: pointer;
  sup {
    display: none !important;
  }
}

.calendar-widget-table tbody .calendar-header td {
  background-color: $themeColor;
}

.news-teaser {
  padding: 30px 0px;
  border-bottom: 2px solid #eee;
  display: block;
  float: left;
  width: 100%;

  h3 {
    color: $themeColor;
    font-size: 1.5em;
  }
  img {
    @include border-radius(50%);
  }
}

.noticesBG {
  position: fixed;
  display: none;
  top: 0px;
  left: 0px;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.noticesContainer {
  position: fixed;
  z-index: 9999;
  top: 50%;
  display: none;
  left: 50%;
  @include transform($center);
  padding: 30px;
  background-color: #dedede;
  max-width: 100%;
  width: 500px;
}
.NoticeTitle {
  text-transform: uppercase;
  font-weight: bold;
  padding: 0px !important;
  border-bottom: none !important;
  font-size: 1.3em;
}
.readMoreLinkNotices {
  font-size: 0.8em;
  border: 2px solid #333;
  padding: 3px 5px;
  margin-top: 10px;
  display: inline-block;
}
.fullWidth {
  position: relative;
  clear: both;
  width: 100%;
  float: left;
}
.closeNotices {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-right: 20px;
  color: $themeColor;
  font-size: 0.8em;
  cursor: pointer;
  z-index: 9999;
}
.closeNotices:after {
  content: "x";
  position: absolute;
  color: #d92d2a;
  display: block;
  right: 5px;
  top: 49%;
  text-transform: uppercase;
  @include transform(translateY(-50%));
}
.noticesInner {
  max-height: 90vh;
  overflow-y: auto;
  padding: 10px;
  * {
    color: #333;
  }
  .col-12 {
    padding: 20px 0px;
    border-bottom: 2px solid #cccccc;
  }
}

.MainMenuClose {
  display: none !important;
}

.mainContent {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    width: 100%;
    padding: 10px 20px;
    background-color: #eee;
    border: none !important;
    margin-bottom: 10px;
    margin-top: 5px;
    width: 100%;
    max-width: 100%;

    font-weight: lighter;
  }

  label {
    font-weight: lighter;
  }

  input[type="submit"] {
    padding: 10px 20px !important;
    display: block;
    width: 100%;
    border: none !important;
    color: #fff !important;
    font-weight: lighter;
    margin-top: 10px;
    @include transition($t1);
    @include border-radius(3px);
    max-width: 100%;
    background-color: #1b3080;
    &:hover {
      background-color: #5a5962 !important;
    }
  }
  #ForgotPassword {
    margin-top: 20px;
    a {
      font-weight: lighter;
      color: #1d1e3c;
    }
  }
}
.addToCalendar,
.attachment,
.readMoreLink {
  padding: 10px 20px !important;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 0.7em !important;
  cursor: pointer;
  * {
    font-size: 1em;
    color: #fff;

    @include transition($t1);
  }
  border: none !important;
  color: #fff !important;
  font-weight: lighter;
  margin-top: 10px;
  @include transition($t1);
  @include border-radius(3px);
  background-color: $themeColor;
  &:hover {
    background-color: #4b4b4b !important;
  }
}

/** Inputs  **/
#content {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    width: 100%;
    padding: 10px 20px;
    background-color: #eee;
    border: none !important;
    margin-bottom: 10px;
    margin-top: 5px;
    width: 100%;
    font-weight: lighter;
  }

  label {
    font-weight: lighter;
  }

  input[type="submit"] {
    padding: 10px 20px !important;
    display: block;
    width: 100%;
    border: none !important;
    color: #fff !important;
    font-weight: lighter;
    margin-top: 10px;
    @include transition($t1);
    @include border-radius(3px);
    background-color: $themeColor;
    max-width: none !important;
    width: auto !important;
    display: inline-block;

    &:hover {
      background-color: #71142b !important;
    }
  }
  #ForgotPassword {
    margin-top: 20px;
    a {
      font-weight: lighter;
      color: #1d1e3c;
    }
  }
}
/** Inputs  **/

.vacancy {
  padding: 30px 0px;
  border-bottom: 2px solid #eee;
  display: block;
  float: left;
  width: 100%;
}

.heading-2 {
  color: $themeColor !important;
  font-weight: lighter;
  * {
    color: $themeColor !important;
    font-size: 1em;
    font-weight: lighter;
  }
}
.archives {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  clear: both;
  padding: 0px !important;
  margin: 0px 0px 30px 0px !important;

  .archive {
    position: relative;
    display: block;
    float: left;
    clear: both;
    color: #fff;
    width: 100%;
    margin: 5px 0px !important;
    cursor: pointer;
    @include transition($t1);
    img {
      position: absolute;
      right: 10px;
      top: 50%;
      max-width: 17px;
      @include transform(translateY(-50%));
    }
    a {
      display: block;
      width: 100%;
      clear: both;
      float: left;
      padding: 8px 30px 8px 15px;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 1;
      height: 100%;
    }
    * {
      @include transition($t1);
      color: #fff !important;
    }

    @include border-radius(3px);

    background-color: $themeColor !important;

    &:hover {
      background-color: #c2a544 !important;
      * {
        color: #fff !important;
      }
    }
  }
}

.SubDate {
  margin-top: 20px;
  font-size: 1.4em;
  margin-bottom: 10px;
}

.archive {
  * {
    color: #fff !important;
  }
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload {
  position: relative;
  display: block;
  width: 100%;
  max-width: 500px;
  clear: both;
  float: left;
  padding: 10px 45px 12px 30px;
  border: 1px solid $themeColor;
  margin: 5px 0px;
  background-color: transparent;
  @include transition($t1);
  .uploadTitle {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: normal !important;
    color: $themeColor !important;
    display: inline-block;
  }
  a {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  .fileIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    @include transform(translateY(-50%));

    background-color: $themeColor;
    display: inline-block;
    float: right;
    width: 25px;
    height: 30px;
    @include transition($t1);
    span {
      @include transition($t1);
      color: #fff !important;
      background-color: #000;
      height: 13px;
      width: 100%;
      padding-top: 2px;
      font-size: 7px;
      text-transform: uppercase;
      display: block;
      text-align: center;
      margin-top: 8px;
    }
  }

  &:hover {
    background-color: #e2e0df !important;
    color: #1b3080 !important;
    .uploadTitle {
      /*color: #000 !important;*/
    }
    /*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
  }
}

.upload {
  /*padding: 10px;
	border-bottom: 2px solid #eee;*/
  * {
    vertical-align: middle;
    font-weight: lighter !important;
  }
}

.galleryImage {
  display: block;
  width: 33.33%;
  position: relative;
  padding-bottom: 33.33%;

  .galleryImageInner {
    position: absolute;
    overflow: hidden;
    width: 95%;
    height: 95%;
    left: 50%;
    top: 50%;
    background-color: #2b2b2b;
    @include transform(translate(-50%, -50%));
    .gallerImageBg {
      @include transition($t1);
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-positon: center;
      left: 0px;
      top: 0px;
      opacity: 1;
    }
    .gallerImageText {
      opacity: 0;
      text-align: center;
      width: 95%;
      @include transition($t1);
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff !important;
      @include transform(translate(-50%, -50%) scale(0.5));
    }
  }
  &:hover {
    .galleryImageInner {
      .gallerImageBg {
        opacity: 0.2 !important;
      }
      .gallerImageText {
        @include transform(translate(-50%, -50%) scale(1));
        opacity: 1 !important;
      }
    }
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 2;
  }
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
  height: 500px;
  li {
    height: 100%;
    text-align: center;
    img {
      display: inline-block;
      height: 100%;
    }
  }
}
.innerSlideGal {
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  a {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}

.GalNext,
.GalPrev {
  cursor: pointer;
  position: absolute;
  z-index: 200;
  top: 50%;
  margin-top: 8px;
  @include transform(translateY(-50%));
  i {
    color: #fff !important;
  }
}
.GalNext {
  left: 0px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.9);
}
.GalPrev {
  right: 0px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.9);
}

.bx_pages_frame {
  position: relative;
  #bx_pages {
    margin-top: 20px;
    li {
      background-position: center center;
      cursor: pointer;
      height: 200px;
      opacity: 0.5;
      @include transition(opacity 0.35s);
      &:hover {
        opacity: 1 !important;
      }
    }
  }
  .controls {
    i {
      position: absolute;
      top: 50%;
      color: $mainColor2;
      font-size: 24px;
      margin-top: -12px;
      cursor: pointer;
      @include transition(all 0.5s);
      &:hover {
        opacity: 0.5;
      }
    }
    i.prev {
      left: 0;
    }
    i.next {
      right: 0;
    }
  }
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  a {
    font-size: 0;
    line-height: 0;
  }
}

.grid-item,
.grid-sizer {
  position: relative;
  width: 25%;
  float: left;
  padding: 2px;
  cursor: pointer;
  @include transition(all 0.5s);
  img {
    width: 100%;
    height: auto;
  }
}

.grid-item.hoverState {
  opacity: 0.7;
}

.grid-item--width2 {
  width: 400px;
}

.galleryImage {
  width: 33.33%;
  float: left;
  position: relative;
  padding-bottom: 33.33%;

  .galleryImageInner {
    position: absolute;
    overflow: hidden;
    width: 95%;
    height: 95%;
    left: 50%;
    top: 50%;
    background-color: #2b2b2b;
    @include transform(translate(-50%, -50%));
    .gallerImageBg {
      @include transition($t1);
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-positon: center;
      left: 0px;
      top: 0px;
      opacity: 1;
    }
    .gallerImageText {
      opacity: 0;
      text-align: center;
      width: 95%;
      @include transition($t1);
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff !important;
      @include transform(translate(-50%, -50%) scale(0.5));
    }
  }
  &:hover {
    .galleryImageInner {
      .gallerImageBg {
        opacity: 0.2 !important;
      }
      .gallerImageText {
        @include transform(translate(-50%, -50%) scale(1));
        opacity: 1 !important;
      }
    }
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 2;
  }
}
#CustomLoginForm_LoginForm {
  max-width: 400px;
  width: 100%;
}

.staffProfile {
  .staffProfilePic {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center center;
    border-radius: 20px;
  }
}
.HorizontalStack {
  .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
    .profilePic {
      margin: 0 !important;
    }
  }
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
  .staffProfile {
    display: inline-block;
    width: 100%;
    margin: 30px;
    .profilePic {
      margin: 0 30px 0 0;
      float: left;
      display: inline-block;
    }
    .bio {
      min-width: 50%;

      p {
        display: block;
      }
    }
  }
}

.StaffPageStack {
  .staffProfile {
    text-align: center;
    margin-bottom: 60px;
    .bio {
      p {
        text-align: justify;
        width: 100%;
        max-width: 800px;
        margin: 0 auto 20px auto;
      }
    }
  }
}

.StaffPageNoPic {
  .staffProfile {
    text-align: center;
    margin-bottom: 60px;
    .bio {
      p {
        text-align: justify;
        width: 100%;
        max-width: 800px;
        margin: 0 auto 20px auto;
      }
    }
  }
}

.bio {
  h4 {
    font-weight: normal;
    font-size: 1.2em;
  }

  h3 {
    text-transform: uppercase;
    font-weight: lighter;
  }
}

.HorizontalStack .staffProfile {
  width: auto;
  margin: 0 30px 30px 0;
}

.VerticalStack {
  margin: 0px;

  .staffProfile {
    margin: 0px;
    padding: 30px 0px;
    border-bottom: 2px solid #eee;
  }
}
.NameDepOnly {
  margin: 0px;

  .staffProfile {
    margin: 0px;
    padding: 30px 0px;
    border-bottom: 2px solid #eee;
  }
}
.HorizontalStack {
  .profilePic {
    padding-bottom: 20px;
  }
}

.profilePic img {
  display: inline-block;
  padding-bottom: 20px;
}

.VerticalStack {
  .profilePic img {
    display: inline-block;
    padding-bottom: 0px;
  }
}

#SearchResults {
  padding: 0px;
  margin: 0px;
  display: block;
  float: left;
  width: 100%;
  font-size: 0.8em;
  li {
    padding: 30px 0px;
    border-bottom: 2px solid #eee;
    margin: 0px;
    display: block;
    float: left;
    width: 100%;
    list-style: none !important;
  }
  .readMoreLink {
    font-size: 1em !important;
  }
}

.NewsletterItem {
  display: block;
  width: 20%;
  float: left;
  padding: 10px;
  .NewsletterInner {
    display: block;
    position: relative;
    width: 100%;
    @include border-radius(3px);
    @include transition($t1);
    border: 5px solid #fff;
    @include box-shadow(0px 0px 10px 0px #9c9c9c);
    overflow: hidden;
    .newsletterThumb {
      float: left;
      clear: both;
      background-color: #eee;
      width: 100%;
      background-image: url(../images/newsletter.png);
      background-size: 60%;
      background-repeat: no-repeat;
      background-position: center;
      padding-bottom: 110%;
    }
    a {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }
  &:hover {
    .NewsletterInner {
      border: 5px solid $themeColor !important;
    }
  }
  .newsletterTitle {
    padding: 10px;
    font-size: 0.8em;
    float: left;
    width: 100%;
    text-align: center;
    clear: both;
  }
}

.Newsletter {
  width: 20%;
  padding-bottom: 20%;
  position: relative;
  min-width: 200px;
  min-height: 200px;
  display: inline-block;
  float: none;
}
.NewsLetterInner {
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  border-radius: 3px;
  overflow: hidden;
  position: absolute;
  border: 2px solid #d2d2d2;
  background-color: #f9f9f9;
  transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -webkit-transition: all 0.35s;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
  -o-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0);
}

.NewsLetterInner .imgContainer {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.Newsletter a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  left: 0px;
  top: 0px;
}
.Newsletter:hover .NewsLetterInner {
  border: 2px solid #d2d2d2;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.Newsletter .downloadInfo {
  position: absolute;
  transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  left: 50%;
  color: #777;
  width: 98%;
  text-align: center;
  font-size: 1em;
  bottom: 10%;
  transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo {
  color: #e42c25 !important;
}

.NewsletterTitle {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 30px;
  margin-top: 50px;
  text-align: center;
  font-size: 1.5em;
  color: #303c68 !important;
  * {
    font-weight: lighter;
    color: #303c68 !important;
  }
}

.InnerPage {
  .calPrev,
  .calNext {
    top: 37px;
  }
  .calendar-widget-table,
  .outerCalendarContainer {
    width: 100%;
  }
  .eventContainer {
    display: none !important;
  }
  .calHeader {
    .col-7,
    .col-5 {
      width: 100%;
      text-align: center;
    }
    .col-5 {
      display: none !important;
    }
  }
}
.group1 {
  @include transition($t1);
  opacity: 0;
}
.PageLoaded {
  .group1 {
    opacity: 1 !important;
  }
}
#gmap_canvas {
  min-height: 400px;
}

/** General Site layouts **/

.CenterItems {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
}

.slink {
  cursor: pointer;
}

.translate {
  cursor: pointer;
}
.TranslateBG {
  display: none;
  position: fixed;
  top: 0px;
  z-index: 9999;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 37, 89, 0.76);
}
#google_translate_element {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  @include transform($center);
}

#gmap_canvas {
  min-height: 500px;
}

/*** Scroll Bar ***/
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 0;
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  background-color: #1c2023;
  @include border-radius(0px);
}
::-webkit-scrollbar-thumb {
  background-color: $themeColor;
  @include border-radius(0px);
}
::-webkit-scrollbar-thumb:vertical {
}
::-webkit-scrollbar-thumb:horizontal {
}
::-webkit-scrollbar-thumb:hover {
  background-color: $themeColor;
}
/*** Scroll Bar ***/
.ScrollBar::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}
.ScrollBar::-webkit-scrollbar-button:start:decrement,
.ScrollBar::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 0;
  background-color: transparent;
}
.ScrollBar::-webkit-scrollbar-track-piece {
  background-color: #325f75;
  @include border-radius(0px);
}
.ScrollBar::-webkit-scrollbar-thumb {
  background-color: #4482a0;
  @include border-radius(0px);
}
.ScrollBar::-webkit-scrollbar-thumb:vertical {
}
.ScrollBar::-webkit-scrollbar-thumb:horizontal {
}
.ScrollBar::-webkit-scrollbar-thumb:hover {
  background-color: #4482a0;
}

.TopLevel::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}
.TopLevel::-webkit-scrollbar-button:start:decrement,
.TopLevel::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 0;
  background-color: transparent;
}
.TopLevel::-webkit-scrollbar-track-piece {
  background-color: #9c1816;
  @include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb {
  background-color: #ca1916;
  @include border-radius(0px);
}
.TopLevel::-webkit-scrollbar-thumb:vertical {
}
.TopLevel::-webkit-scrollbar-thumb:horizontal {
}
.TopLevel::-webkit-scrollbar-thumb:hover {
  background-color: #ca1916;
}

.db {
  display: block;
}

fieldset {
  border: none;
}

/*** Search Form ***/

/*** HomePage ***/
.banner {
  position: relative;
  overflow: hidden;
  float: left;
  width: 100%;
  height: 100vh;
  @include background-cover(url("../images/bg.jpg"));

  .darken {
    @include stretchCover(rgba(0, 0, 0, 0.5), absolute, 1);
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    @include background-cover(url("../images/bg.jpg"));
  }
}

.bannerBg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  @include background-cover(url("../images/bg.jpg"));
}

#google_translate_element {
  border: 30px solid #312d5e;
}

.searchToggle {
  cursor: pointer;
  z-index: 8000;
  * {
    color: #fff;
  }
  position: fixed;
  top: 5px;
  left: 10px;
  span {
    display: inline-block;
    margin-left: 10px;
  }
}

/*** Page ***/
.Page {
  .banner {
    height: 50vh;
  }
}
/*** Footer ***/
footer {
  padding: 30px 0px 0px 0px;
}

/*** Menu ***/
#Menu {
  float: left;
  z-index: 9999;
  width: 100%;
  text-align: center;
  display: inline-block;
  text-align: right;
  * {
    color: #000;
  }
  /*
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	*/
  .MenuContainer {
    text-align: center;
    position: relative;
    .LevelOne {
      display: inline-block;
      text-align: center;
      position: relative;
      a {
        font-size: 1em;
        @include transition($t1);
        padding: 14px 20px 24px 20px;
        font-size: 1.1em;
        line-height: 3em;
        display: inline-block;
        line-height: 1.1em;
      }
    }
  }

  .SubMenu {
    @include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
    width: 250px;
    height: 0px;
    font-size: 0.7em;
    overflow: hidden;
    display: block;
    z-index: -9999;
    left: 50%;
    text-align: left;
    position: absolute;
    background-color: #4b4b4b;
    border-radius: 0px;
    margin-top: 0px;
    /* padding: 3px 10px 6px 10px;*/
    opacity: 0;
    transition: transform 0.35s 0.12s, opacity 0.35s 0.12s;
    -o-transition: transform 0.35s 0.12s, opacity 0.35s 0.12s;
    -ms-transition: transform 0.35s 0.12s, opacity 0.35s 0.12s;
    -moz-transition: transform 0.35s 0.12s, opacity 0.35s 0.12s;
    -webkit-transition: transform 0.35s 0.12s, opacity 0.35s 0.12s;
    @include transform(translateY(20px) translateX(-50%) scale(0.98));

    a {
      display: inline-block;
      padding: 10px 20px !important;
      /*font-size: 0.9em;*/
      line-height: 1.2em !important;
      width: 100%;
      font-weight: bold;
      background-color: transparent;
      @include transition($t1);
      &:hover {
        background-color: rgba(255, 255, 255, 0.2) !important;
      }
    }
  }
  .menuItem-1,
  .menuItem-2 {
    .SubMenu {
      left: 0%;
      @include transform(translateY(20px) translateX(0%) scale(0.98));
    }
  }
  .menuItem-Last {
    .SubMenu {
      left: auto;
      right: 0%;
      @include transform(translateY(20px) translateX(0%) scale(0.98));
    }
  }
  .active .SubMenu {
    opacity: 1 !important;
    @include transform(translateY(0px) translateX(-50%) scale(1) !important);
  }
  .menuItem-1.active .SubMenu,
  .menuItem-2.active .SubMenu {
    opacity: 1 !important;
    @include transform(translateY(0px) translateX(0%) scale(1) !important);
  }
  .menuItem-Last.active .SubMenu {
    opacity: 1 !important;
    left: auto;
    right: 0%;
    @include transform(translateY(0px) translateX(0%) scale(1) !important);
  }

  /*.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}*/
}

.flexslider .flex-direction-nav {
  position: absolute;
  bottom: 16%;
  left: 50%;
  height: 30px;
  z-index: 800;
  width: calc(100% - 80px);
  @include transform($center);
}
.flexslider .slides li {
  position: relative;
  height: 100vh;
}
.quoteBox {
  position: absolute;
  top: 50%;
  left: 150px;
  @include transform(translate(0%, -50%));
  z-index: 2;
  color: #fff;
  font-size: 2em;
  display: block;
  opacity: 1;
  line-height: 1.2em;
  text-transform: uppercase;
  * {
    color: #fff !important;
  }
}

.slideBG {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-image: url(../images/bg.jpg);
}
/* -------------------- General Site layouts -------------------- **/

/* -------------------- Site Build -------------------- **/

/*** Header ***/
header {
  padding: 0px 40px;
  width: 100%;
  top: 0px;
  z-index: 9999;
  position: fixed;
  background-color: #fff;
  perspective: 1000px;
  @include transform(translateY(0%));
  @include box-shadow(0px 0px 6px rgba(41, 56, 90, 0.21));

  .headerDecoration {
    position: absolute;
    left: 0px;
    width: 100%;
    border-bottom: 10px solid #ebeae9;
    height: 10px;
    bottom: 0px;
    opacity: 1;
    @include transition($t1);
  }

  .innerHeader {
    float: left;
    width: 100%;
    position: relative;
    min-height: 133px;
    @include transition($t1);
  }

  #Logo {
    width: 252px;
    display: block;
    position: absolute;
    top: 50%;
    left: 0px;
    @include transition($t1);
    @include transform(translateY(-50%));

    overflow: hidden;
    h1 {
      position: absolute;
      bottom: -184px;
    }
  }

  #DesktopNavigation {
    position: absolute;
    text-align: right;
    bottom: 0px;
    width: 100%;
    right: 0px;
    ul {
      text-align: right;
    }
    ul li {
      text-align: left;
    }
    a:first-of-type {
      margin-left: 0px !important;
    }
    a {
      color: #5b6e94;
      display: inline-block;
      padding: 10px 0px 15px 0px;
      margin: 10px;
      margin-right: 20px;
    }
    a:first-of-type {
      margin-left: 0px !important;
    }
  }

  .navHover {
    position: absolute;
    bottom: 0px;
    height: 10px;
    opacity: 0;
    @include transition(all 1s);
    left: 0px;
    background-color: #f59439;
  }
}

.callus {
  font-weight: lighter;
  position: absolute;
  bottom: 0px;
  backface-visibility: hidden;
  left: 40px;
  background-color: $themeColor;
  padding: 10px 20px;
  @include transition($t1);
  @include transform(translateY(100%) rotateX(0deg));
  transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  backface-visibility: none;
  -webkit-backface-visibility: none;
  -o-backface-visibility: none;
  -ms-backface-visibility: none;
  -moz-backface-visibility: none;
  color: #fff;
  font-size: 1.2em;
  strong {
    font-weight: 400 !important;
    color: #fff !important;
  }
  * {
    color: #fff !important;
  }
}
.topLinks {
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 0px;
  @include transition($t1);
  a {
    width: 35px;
    height: 35px;
    margin-right: 2px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    @include transition($t1);
    @include transform(scale(1));
    &:hover {
      height: 40px;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform($center);
    }
  }
}
.search {
  img {
    width: 20px !important;
  }
  background-color: $themeColor;
}
.mail {
  img {
    width: 20px !important;
  }
  background-color: #4e565c;
}
.twitter {
  img {
    width: 20px !important;
  }
  background-color: #20baed;
}
.facebook {
  img {
    height: 20px !important;
    width: auto !important;
  }
  background-color: #4679ae;
}
.google {
  img {
    width: 20px !important;
  }
  background-color: #e40806;
}
.footerLinks a,
.topLinks a {
  background-image: url(../images/top-Links.png);
}
.mail {
  background-position: 139px;
}
.twitter {
  background-position: 105px;
}
.facebook {
  background-position: 71px;
}
.google {
  background-position: 35px;
}
.topLinks a img {
  display: none;
}

.scrollNav {
  header {
    .innerHeader {
      min-height: 75px;
    }
    #Logo {
      top: 49%;
      width: 150px;
    }
    .topLinks {
      margin-top: -40px;
      opacity: 0;
      a {
        @include transform(scale(0.5));
      }
    }
  }
  .callus {
    opacity: 0;
    @include transform(translateY(100%) rotateX(-90deg));
  }
}

#DesktopNavigation li {
  display: inline-block;
  .subMenu {
    display: none;
    /*max-width: 600px;*/
    width: 200px;
    padding: 0px 10px 13px 10px;
    background-color: #f59439;
    position: absolute;
    z-index: 9999;

    li {
      display: block;
      width: 100%;
      float: left;
      clear: both;
    }
    a {
      display: block;
      width: 100%;
      clear: both;
    }
    &:before {
      opacity: 0;
      content: "";
      position: absolute;
      display: block;
      top: -10px;
      z-index: 9999;
      left: 0px;
      width: 100%;
      height: 10px;
    }
    * {
      color: #fff;
    }
    a {
      padding: 10px 0px 7px 0px;
      margin: 0px 0px !important;
      font-size: 0.9em;
      position: relative;
      &:after {
        @include transition($t1);
        opacity: 0;
        content: "";
        position: absolute;
        display: block;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 5px;
        background-color: #fff;
      }
      &:hover {
        &:after {
          opacity: 1 !important;
        }
      }
    }
  }
  &:hover {
    .subMenu {
      display: block !important;
    }
  }
}

.mobMenu {
  position: absolute;
  top: 58px;
  display: none;
  right: 0px;
  @include transition($t1);
}

.bottomBannerDecoration {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  z-index: 5;
  height: 20px;
  background-color: #ea4c26;
}
.down {
  position: absolute;
  width: 60px;
  left: 50%;
  z-index: 6;
  bottom: 10px;
  @include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.2));
  @include transform(translateX(-50%) translateY(50%));
}
#Home1 {
  z-index: 2;
}
#Home2 {
  z-index: 1;
  padding: 100px 20px;
  .inner {
    width: 800px;
  }
}

.TextFormat {
  font-size: 1.2em;
  font-weight: lighter;
  line-height: 1.5em;
}
.readMore {
  background-color: #f59439;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9em;
  padding: 7px 20px;
  cursor: pointer;
  margin-top: 30px;
  display: inline-block;
  @include transition($t1);
  &:hover {
    background-color: #4c4e4d;
  }
}

#Home2 {
  .pageTitle {
    color: #f59439;
  }
}
#Home3 {
  background-color: #27506c;
  color: #fff;
  text-align: center;
  font-size: 0.9em;
  font-size: 1.5em;
  padding: 20px;
  text-transform: uppercase;
}
#Home4 {
  padding: 70px 20px;
}
.schoolIcon {
  @include transition($t1);
  padding-bottom: 10%;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 70%;
    @include transform($center);
    @include transition($t1);
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  a {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  &:hover {
    opacity: 0.8;
    img {
      -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
      filter: grayscale(0%);
    }
  }
}
.pageTitle {
  text-transform: uppercase;
}
#Home5 {
  padding: 100px 20px;
  .inner {
    width: 800px;
  }
  .title1 {
    text-transform: uppercase;
    color: #396e88;
  }
  .TitleIcon {
    width: 60px;
    margin: 20px;
    @include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.2));
  }
  .pageTitle {
    color: #396e88;
    text-transform: uppercase;
  }
}

a.bx-pager-link {
  @include transition($t1);
  background: #c5c5c5;
  color: transparent;
  width: 100px;
  display: inline-block;
  height: 10px;
}
a.bx-pager-link.active {
  background: #396e88;
}
.bx-pager-item {
  display: inline-block;
  padding: 0px 20px;
}
.bx-pager {
  padding-top: 70px;
}

#Home6 {
  padding: 100px 20px;
  background-color: #f59439;
  * {
    color: #fff;
  }
  .TitleIcon {
    width: 60px;
    margin: 20px;
    @include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.2));
  }
  .bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    -webkit-background-attachment: fixed;
    background-image: url(../images/bg.jpg);
  }
  .StatContainer {
    padding-bottom: 100%;
  }
  .StatCircle {
    position: absolute;
    top: 50%;
    left: 50%;
    @include border-radius(50%);
    @include transform($center);
    background-color: #fff;
    opacity: 0.3;
    width: 80%;
    height: 80%;
  }
  .StatsText {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform($center);
  }
  .StatTitle {
    text-transform: uppercase;
    font-size: 1.2wm;
  }
  .Points {
    font-size: 5.5em;
    font-weight: 500;
  }
}

#Footer {
}
#FooterTop {
  background-color: #396e88;
  padding: 50px 20px;
}
#FooterBottom {
  padding: 30px 20px;
}
.SubscriptionFormTitle {
  font-size: 2.2em;
  color: #fff;
  text-transform: uppercase;
}
#FooterTop {
  #Form_SubscriptionForm_action_submit {
    height: 49px;
    background-color: #f46539;
    color: #fff;
    border: none !important;
    display: block;
    float: left;
    padding: 10px 20px;
    text-transform: uppercase;
  }
  #Form_SubscriptionForm_FullName,
  #Form_SubscriptionForm_School,
  #Form_SubscriptionForm_Email {
    margin-left: 5px;
    padding: 15px 30px;
    color: #396e88;
    text-transform: uppercase;
    height: 49px;
    border: none !important;
  }
  label {
    display: none !important;
  }
  form {
    * {
      display: block;
      float: left;
    }
  }
}
#Form_SubscriptionForm {
  cursor: pointer;
}
.footerFormContainer {
  text-align: right;
  .footerForm {
    display: inline-block;
    text-align: left;
    p {
      color: #fff !important;
    }
  }
}

.footerLinks {
  padding-top: 15px;
  opacity: 1;
  a {
    width: 35px;
    height: 35px;
    margin-right: 2px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    @include transition($t1);
    @include transform(scale(1));
    &:hover {
      opacity: 0.8;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform($center);
    }
  }
}
.footerLogo {
  img {
    width: 250px;
  }
}
#FooterBottom {
  font-size: 1.3em;
  * {
    color: #968d8c;
  }
  a {
    color: #f4714a;
  }
}

.bannerText {
  position: absolute;
  width: 90%;
  left: 50%;
  z-index: 4;
  bottom: 44%;
  text-align: center;
  @include transform(translateX(-50%));
  * {
    text-transform: uppercase;
    color: #fff;
  }
  h1 {
    font-size: 3em;
    font-weight: 400;
  }
  span,
  a {
    position: relative;
    display: inline-block;
    margin-top: 20px;
    font-size: 1.2em;
    padding: 10px 20px;
    border: 1px solid transparent;
    @include transition($t1);
    &:before {
      content: "";
      position: absolute;
      display: block;
      left: 50%;
      width: 80px;
      height: 1px;
      background-color: #fff;
      top: -10px;
      @include transform(translateX(-50%));
    }
  }
  a:hover {
    /* background-color: #e15e41; */
    border: 1px solid #e15e41;
    box-shadow: 0px 0px 20px #e15e4185;
  }
}

.FundraisingPage,
.AboutUsPage {
  .banner {
    .bg {
      width: 50%;
    }
  }
  #Section3,
  #Section2 {
    z-index: 1;
    padding: 100px 20px;
    .pageTitle {
      color: #396e88;
    }
    .inner {
      width: 800px;
    }
    .TitleIcon {
      width: 60px;
      margin: 20px;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      -o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

#CustomContent {
  z-index: 1;
  padding: 100px 20px;
  .pageTitle {
    color: #396e88;
  }
  .inner {
    width: 800px;
  }
  .TitleIcon {
    width: 60px;
    margin: 20px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.MobileSection {
  .title1 {
    color: #396e88;
    text-transform: uppercase;
  }
}

.AboutUsContent {
  position: absolute;
  height: 100%;
  z-index: 2;
  background-color: #396e88;
  right: 0px;
  width: 55%;
}

.recentWorkLink {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  border: 1px solid #fff;
  text-transform: uppercase;
  @include transition($t1);
  &:hover {
    background-color: #4c4d4c !important;
    border-color: #4c4d4c !important;
  }
}

.innerAboutUs {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: calc(100% - 220px);
  margin-top: 59px;
  @include transform($center);
  text-align: center;
  width: 80%;
  padding: 40px;
  overflow: hidden;
  overflow-y: auto;
  * {
    color: #fff;
  }
  .TitleIcon {
    width: 60px;
    margin: 20px;
    @include box-shadow(0px 0px 10px 0px rgba(0, 0, 0, 0.2));
  }
  .title1 {
    text-transform: uppercase;
  }
}

#imgScroll,
#RecentWork {
  background-color: #f3f3f3;
  /*background-color: #2b2b2b;*/
  color: #fff;
  /*opacity: 0;*/
  @include transition(all 1s);

  .PortfolioTitle {
    background-color: #f4714a;
    padding: 2.15% 3% 3% 3%;
    @include transform(translateX(-50%));
    .SliderTitle {
      text-transform: uppercase;
      font-size: 2.3vw;
      font-weight: lighter;
    }
    * {
      color: #fff;
    }
  }
  .group3 {
    opacity: 0 !important;
  }
}
#imgScroll.activeIn,
#RecentWork.activeIn {
  /*opacity: 1 !important;*/
  .group3 {
    opacity: 1 !important;
  }

  .shadow {
    height: 50% !important;
    width: 120% !important;
    opacity: 0.2 !important;
  }

  .group3.image {
    @include transform(rotateY(0deg) translateX(0%) !important);
  }

  .group3.PortfolioTitle {
    @include transform(translateX(0%) !important);
  }
}

#imgScroll {
  perspective: 1000px;
}

.image {
  overflow: hidden;
  width: 25%;
  padding-bottom: 25%;
  float: left;

  @include transform(rotateY(50deg) translateX(90%));
  backface-visibility: hidden;

  float: left;
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: url(../images/bg.jpg);

  a {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
    width: 100%;
    height: 100%;
  }
  .hover {
    background-color: #2b2b2b;
    background-color: rgba(43, 43, 43, 0.95);
    position: absolute;
    bottom: -50%;
    width: 100%;
    height: 50%;
    left: 0px;
    @include transition($t1);
    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      @include transform($center);
    }
    .part-1 {
      font-size: 1.5em;
      font-weight: lighter;
      color: #fff;
      text-transform: uppercase;
      border-bottom: 1px solid $themeColor;
      padding-bottom: 10px;
    }
    .part-2 {
      font-weight: lighter;
      color: #fff;
      padding-top: 10px;
    }
  }
  &:hover .hover {
    bottom: 0% !important;
  }
}

.isotope {
  -webkit-transition-property: height;
  -moz-transition-property: height;
  -ms-transition-property: height;
  -o-transition-property: height;
  transition-property: height;
}

/*** Portfolio ***/
.RecentWorkSectionInner,
.PortfolioItem {
  overflow: hidden;
  width: 25%;
  float: left;

  backface-visibility: hidden;

  float: left;
  .bg {
    position: absolute;
    @include transition(all 1s);
    @include transform(scale(1));
    top: -1%;
    left: -1%;
    width: 102%;
    height: 102%;
    background-size: cover;
    background-position: center;
    position: relative;
    background-image: url(../images/bg.jpg);
  }
  &:hover {
    .bg {
      @include transform(scale(1.2) !important);
    }
  }

  a {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
    width: 100%;
    height: 100%;
  }
  .hover {
    background-color: #2b2b2b;
    background-color: rgba(43, 43, 43, 0.95);
    position: absolute;
    bottom: -50%;
    width: 102%;
    height: 50%;
    left: -1%;
    @include transition($t1);
    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      @include transform($center);
    }
    .part-1 {
      font-size: 1.5em;
      font-weight: lighter;
      color: #fff;
      text-transform: uppercase;
      border-bottom: 1px solid $themeColor;
      padding-bottom: 10px;
    }
    .part-2 {
      font-weight: lighter;
      color: #fff;
      padding-top: 10px;
    }
  }
  &:hover .hover {
    bottom: 0% !important;
  }
}
/*** Portfolio ***/

#MemberLoginForm_LoginForm {
  max-width: 500px;
}

.RecentWorkSectionInner {
  padding-bottom: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url(../images/bg.jpg);
}

.CaseStudiesContent {
  text-align: center;
  padding: 100px 20px;
  background-color: #f5f4f4;
}
.CaseStudySliderImage {
  display: none;
}

.CaseStudiesContent {
  .bx-pager-link {
    background-color: #e2e0e0;
  }
  .pageTitle {
    color: #396e88;
  }
  #SchoolSliderContainer {
    min-width: 50%;
    width: 50%;
  }
  .CaseStudySliderImage {
    background-image: url(../images/bg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    background-position: center;
    top: 50%;
    @include transition($t1);
    display: block;
    height: 90%;
    width: 30%;
    right: 10%;
    @include transform(translateY(-50%));
  }
}

.innerCenter {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  clear: both;
}

.ContentForm {
  form {
    padding-bottom: 60px;
  }
  label {
    color: #396e88 !important;
    font-weight: lighter !important;
    // text-transform: uppercase !important;
    font-size: 1em;
    font-size: 1em;
  }
  textarea,
  input[type="email"],
  input[type="password"],
  input[type="text"] {
    padding: 5px 10px;
    border: 1px solid #2b2b2b;
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
    max-width: 400px;
    @include transition($t1);
    background-color: transparent;
  }
  input[type="submit"] {
    background-color: #f59439;
    color: #fff;
    border: none !important;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 7px 20px;
    margin-top: 30px;
    display: inline-block;
    @include transition($t1);
    &:hover {
      background-color: #4c4e4d;
    }
  }
  textarea.ActiveInput,
  input[type="email"].ActiveInput,
  input[type="password"].ActiveInput,
  input[type="text"].ActiveInput {
    background-color: #eee;
    border: 1px solid $themeColor !important;
  }
}

.torange {
  color: #f59439;
}

.pagePadding {
  padding-top: 220px;
}

.ContactForm {
  .pageTitle {
    color: #396e88;
  }
}
.MobileSection {
  display: none;
}

.SearchContainer {
  display: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
  @include transform(translateY(100%));
  background-color: #e0e0e0;
  padding: 7px;
  .middleColumn,
  button,
  #SearchForm_SearchForm_Search_Holder {
    float: left;
    display: block;
  }

  button {
    background-color: #e15e41;
    border: none !important;
    color: #fff !important;
    padding: 5px 10px;
    i {
      color: #fff !important;
    }
  }
  input[type="text"] {
    border: 0px;
    padding: 5px 10px;
    color: #5f5e6d;
  }
}

.mobMenu {
  position: absolute;
  top: 58px;
  right: 0px;
  @include transition($t1);
}

.welcomeContainer {
  .part-2 {
    display: none;
  }
}

.RecentWorkSectionInner {
  .hover {
    @include transform($center scale(0.8) !important);
    background-color: #ea4c26;
    top: 50% !important;
    bottom: auto !important;
    left: 50% !important;
    width: 90%;
    height: 90%;
    opacity: 0;
    .center {
      padding-bottom: 20px;
      border-bottom: 1px solid #fff;
    }
  }
  .hover .part-1 {
    font-size: 1.2em;
    text-align: left;
    border-bottom: none !important;
  }
  &:hover {
    .hover {
      @include transform($center scale(1) !important);
      opacity: 1 !important;
    }
  }
}

.WhiteBanner {
  .innerAboutUs * {
    color: #5b6e94 !important;
  }
  background-color: #fff;
  .pageTitle {
    color: #396e88 !important;
  }
}

.ContentForm {
  ::placeholder {
    color: #999;
  }
}
.SearchContent {
  padding-top: 180px;
}
/* -------------------- Site Build -------------------- **/

/* -------------------- Hidden Folder Page -------------------- **/

#drag {
  height: 100%;
  width: 100%;
  position: absolute !important;
}
.PageContentSection {
  padding-top: 200px;
  .pageTitle {
    color: #27506c;
    padding-bottom: 30px;
  }
  .inner {
    width: 800px;
  }
}
.HiddenPageContainer {
  overflow: hidden;
  margin-top: 0px;
  padding: 0px 20px;

  .OuterContainer {
    min-height: 160vh;
  }
  .inner {
    transform-origin: 0% -40%;
    -o-transform-origin: 0% -40%;
    -ms-transform-origin: 0% -40%;
    -moz-transform-origin: 0% -40%;
    -webkit-transform-origin: 0% -40%;
    width: 1300px;
    position: absolute;
    max-width: 100%;
    left: 50%;
    top: 50%;
    @include transform(translate(-50%, -67%));
  }
  .shadow {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+52,000000+86&0.65+0,0.4+19,0+61,0+100 */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.4) 19%,
      rgba(0, 0, 0, 0.09) 52%,
      rgba(0, 0, 0, 0) 61%,
      rgba(0, 0, 0, 0) 86%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.4) 19%,
      rgba(0, 0, 0, 0.09) 52%,
      rgba(0, 0, 0, 0) 61%,
      rgba(0, 0, 0, 0) 86%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.4) 19%,
      rgba(0, 0, 0, 0.09) 52%,
      rgba(0, 0, 0, 0) 61%,
      rgba(0, 0, 0, 0) 86%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    filter: blur(20px);
    opacity: 0.4;
    z-index: 2;
    height: 57%;

    @include transition($t1);
    @include transform($center);
  }
  .shadow2 {
    filter: blur(20px);
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+52,000000+86&0.65+0,0.4+19,0+61,0+100 */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.4) 19%,
      rgba(0, 0, 0, 0.09) 52%,
      rgba(0, 0, 0, 0) 61%,
      rgba(0, 0, 0, 0) 86%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.4) 19%,
      rgba(0, 0, 0, 0.09) 52%,
      rgba(0, 0, 0, 0) 61%,
      rgba(0, 0, 0, 0) 86%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.4) 19%,
      rgba(0, 0, 0, 0.09) 52%,
      rgba(0, 0, 0, 0) 61%,
      rgba(0, 0, 0, 0) 86%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    position: absolute;
    top: 74%;
    left: 50%;
    opacity: 0.6;
    width: 59%;
    filter: blur(20px);
    z-index: 2;
    height: 14%;
    @include transition($t1);
    @include transform($center);
  }
  .folderContainer {
    z-index: 9;
    padding-bottom: 100%;
    webkit-perspective: 1000px;
    perspective: 1000px;
    transform-style: preserve-3d;

    .FlapBack,
    .FlapFront {
      overflow: hidden;
      position: absolute;
      bottom: 0px;
      width: 100%;
      z-index: 2;
      cursor: pointer;
      height: 21%;
      transform-style: preserve-3d;

      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      @include transition(all 1s);
      @include transform(rotateX(0deg));
      transform-origin: 0px 100%;
      -o-transform-origin: 0px 100%;
      -ms-transform-origin: 0px 100%;
      -moz-transform-origin: 0px 100%;
      -webkit-transform-origin: 0px 100%;

      @include border-radius(20px 20px 0px 0px);
      background-color: #fff;
      border: 1px solid #d7d7d7;

      .FlapImage {
        position: absolute;
        bottom: 0px;
        transform-style: preserve-3d;
        left: 50%;
        width: 86%;
        width: 99%;
        height: 100%;
        @include transform(translateX(-50%));
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        background-image: url(../images/Flap.png);
      }
    }

    .FlapBack {
      background-color: #014b96;
      /*backface-visibility: visible;
			webkit-backface-visibility: visible;
			*/
      @include transform(
        translateZ(-13px) rotateX(0deg) rotateY(-180deg) translateX(-100%)
      );
    }
    .hexagon2,
    .hexagon {
      display: none;
      position: absolute;
      bottom: -119%;
      width: 40%;
      left: 30%;
      height: 297%;
      background-color: #fff;
      transform: translateY(0px) rotate(90deg);
    }
    .hexagon2 {
      filter: blur(3px);
      opacity: 0.2;
      background-color: #000;
    }

    .hexagon2:before,
    .hexagon2:after,
    .hexagon:before,
    .hexagon:after {
      content: "";
      position: absolute;
      width: 0;
      border-left: 494px solid transparent;
      border-right: 150px solid transparent;
    }

    .hexagon2:before,
    .hexagon:before {
      @include transform(translateY(0.5px));
      bottom: 100%;
      border-bottom: 86.6px solid #fff;
    }
    .hexagon2:before {
      border-bottom: 86.6px solid #000;
    }

    .hexagon2:after,
    .hexagon:after {
      top: 100%;
      @include transform(translateY(-0.5px));
      width: 0;
      border-top: 86.6px solid #fff;
    }
    .hexagon2:after {
      border-top: 86.6px solid #000;
    }

    .PageOne {
      z-index: 100;
    }
    .PageOne,
    .PageTwo,
    .PageThree {
      /*height: 50%;*/
      height: 47%;
      position: absolute;
      border: 1px solid #d7d7d7;
      width: 33.33%;
      top: 50%;
      @include transition(all 1s);
      background-size: contain;
      transform-style: preserve-3d;
      .back {
        background-color: #fff;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        @include transform(rotateY(-180deg));
      }
      .front {
        background-color: #fff;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
      }
    }
    .folderContainerInner {
      z-index: 40;
      position: absolute;
      transform-style: preserve-3d;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      @include transition($t1);
      @include transform($center rotateY(0deg));
    }
    .PageOne {
      z-index: 4;
      left: 0%;
      @include transform(rotateY(10deg) translateY(-50%));
      /*@include transform( rotateY(180deg) translateY(-50%));*/
      @include transform-origin(100% 0%);
      .back {
        background-image: url(../images/back1.png);
        .FolderPrev {
          display: none;
        }
      }
      .front {
        background-image: url(../images/front1.png);
      }
    }
    .PageTwo {
      z-index: 1;
      left: 33.33%;
      @include transform(translateY(-50%) translateZ(-1px));
      .back {
        background-image: url(../images/back2.png);
      }
      .front {
        background-image: url(../images/front2.png);
      }
    }
    .PageThree {
      z-index: 2;
      left: 66.66%;
      width: 26%;
      @include transform(rotateY(-10deg) translateY(-50%));
      @include transform-origin(0% 0%);
      .back {
        background-image: url(../images/back3.png);
        .FolderPrev {
          display: none;
        }
      }
      .front {
        background-image: url(../images/front3.png);
      }
    }
  }

  &.Stage-1 {
    .PageOne {
      @include transform(rotateY(179deg) translateY(-50%));
    }
    .PageTwo {
      @include transform(translateY(-50%) translateZ(-1px));
    }
    .PageThree {
      @include transform(rotateY(-180deg) translateY(-50%));
    }
  }
  &.Stage-2 {
    .shadow {
      top: 50%;
      left: 33%;
      width: 120%;
      opacity: 0.4;
      z-index: 2;
      height: 72%;
    }
    .shadow2 {
      left: 31%;
      width: 80%;
    }
    .PageOne {
      @include transform(rotateY(15deg) translateY(-50%));
    }
    .PageTwo {
      @include transform(translateY(-50%) translateZ(-1px));
    }
    .PageThree {
      @include transform(rotateY(-180deg) translateY(-50%));
    }
  }
  &.Stage-3 {
    .shadow {
      left: 44%;
      width: 150%;
      height: 70%;
    }
    .shadow2 {
      width: 100%;
    }
    .PageOne {
      @include transform(rotateY(15deg) translateY(-50%));
    }
    .PageTwo {
      @include transform(translateY(-50%) translateZ(-1px));
    }
    .PageThree {
      @include transform(rotateY(-15deg) translateY(-50%));
      /*.FolderNext{
				display: none !important;
			}
			.FolderPrev{
				display: block !important;
			}*/
    }
  }
  &.Stage-4 {
    .shadow {
      left: 44%;
      width: 150%;
      height: 70%;
    }
    .shadow2 {
      width: 100%;
    }
    .PageOne {
      @include transform(rotateY(15deg) translateY(-50%));
      .FolderPrev {
        display: block !important;
        right: 0px;
        left: auto;
      }
    }
    .PageTwo {
      @include transform(translateY(-50%) translateZ(-1px));
    }
    .PageThree {
      @include transform(rotateY(-15deg) translateY(-50%));
      .FolderPrev {
        display: block !important;
        left: 0px;
        right: auto;
      }
    }
    .folderContainerInner {
      @include transform($center rotateY(-180deg));
    }
    .FolderNext {
      display: none !important;
    }
  }
}

.activeFlap .FlapFront {
  @include transform(rotateX(-175deg) !important);
}

.activeFlap .FlapBack {
  @include transform(
    translateZ(-13px) rotateX(-174.7deg) rotateY(-180deg) translateX(-100%)
      !important
  );
}

.FolderPrev,
.FolderNext {
  position: absolute;
  top: 0px;
  height: 100%;
  z-index: 200 !important;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  @include transition($t1);
  cursor: pointer;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform($center);
    color: #fff;
    @include text-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    font-size: 1.5em;
  }
  &:hover {
    opacity: 1;
  }
}

.FullClick {
  background-color: transparent !important;
  width: 100% !important;
}

.PageOne {
  .front {
    .FolderPrev {
      left: 0px;
      right: auto;
    }
  }
  .back {
    .FolderNext {
      right: 0px;
      left: auto;
    }
  }
}
.PageThree {
  .front {
    .FolderNext {
      right: 0px;
      left: auto;
    }
  }
}

.HiddenPageBottom {
  background-color: #27506c;
  padding: 50px 20px;
  * {
    color: #fff;
  }
}
.FirefoxFolderPrev {
  display: none !important;
}
.Firefox {
  .FirefoxFolderPrev {
    z-index: 9999;
    display: block !important;
    position: fixed;
    bottom: 70px;
    width: 40px;
    height: 40px;
    left: 20px;
    top: auto !important;
    opacity: 0.4 !important;
    cursor: pointer;
    @include border-radius(50%);
    background-color: #2b2b2b;
    @include transition($t1);
    &:hover {
      opacity: 1 !important;
    }
    * {
      color: #fff;
    }
  }
  .FirefoxFolderNext {
    z-index: 9999;
    display: block !important;
    position: fixed;
    bottom: 70px;
    width: 40px;
    height: 40px;
    top: auto !important;
    opacity: 0.4 !important;
    cursor: pointer;
    @include border-radius(50%);
    background-color: #2b2b2b;
    @include transition($t1);
    &:hover {
      opacity: 1 !important;
    }
    right: 20px;
    * {
      color: #fff;
    }
  }
}

.zoom {
  position: fixed;
  z-index: 9997;
  bottom: 0px;
  right: 0px;
  padding: 0px 20px;
  background-color: #efefef;
  @include border-radius(4px 0px 0px 0px);
}

@media all and (max-width: 1400px) {
  .HiddenPageContainer .inner {
    width: 800px;
  }
}
@media all and (max-height: 740px) {
  .HiddenPageContainer .inner {
    width: 800px;
  }
}

input[type="range"] {
  background-color: #eee;
  height: 10px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #50555c;
  background: #50555c;
  border-radius: 14px;
  border: 0px solid #999;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #999;
  border: 0px solid #999;
  height: 16px;
  width: 16px;
  border-radius: 12px;
  background: #e15f42;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #50555c;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #50555c;
  background: #50555c;
  border-radius: 14px;
  border: 0px solid #999;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #999;
  border: 0px solid #999;
  height: 10px;
  width: 40px;
  border-radius: 12px;
  background: #e15f42;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #50555c;
  border: 0px solid #999;
  border-radius: 28px;
  box-shadow: 1px 1px 1px #50555c;
}
input[type="range"]::-ms-fill-upper {
  background: #50555c;
  border: 0px solid #999;
  border-radius: 28px;
  box-shadow: 1px 1px 1px #50555c;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 40px;
  border-radius: 12px;
  background: #529de1;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #50555c;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #50555c;
}

#Home5 .title1 {
  font-size: 1.5em;
}
/* -------------------- Hidden Folder Page -------------------- **/

.loadingSection {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #fff;
  width: 100%;
  z-index: 9999;
  height: 100%;
  img {
    width: 40px;
    top: 50%;
    left: 50%;
    position: absolute;
    @include transform($center);
  }
}
.pace {
  display: none !important;
}

#Home6 .col-3 {
  float: none !important;
  display: inline-block !important;
}

#Form_SubscriptionForm_action_submit {
  cursor: pointer !important;
}

.NoTransitions {
  @include transition(all 0ms !important);
  * {
    @include transition(all 0ms !important);
  }
}

.error {
  display: inline-block;
  border: 1px solid #da8486;
  margin: 0px 0px 10px 0px;
  padding: 5px 20px;
  font-size: 1em;
  color: #da8486;
  background-color: #ffdfdf;
}

.NotCompatible {
  display: none;
}

/*@media screen and (-webkit-min-device-pixel-ratio:0) 
and (min-color-index:0)
{  
	.HiddenPageContainer{
		display: none !important;
	}
	.NotCompatible{
		display: block !important;
	}
}*/
.ie8,
.ie9,
.MSIE,
.IE11,
.safari8 {
  .HiddenPageContainer {
    display: none !important;
  }
  .NotCompatible {
    display: block !important;
  }
}
/*
_::-webkit-full-page-media, _:future, :root {
	.HiddenPageContainer{
		display: none !important;
	}
	.NotCompatible{
		display: block !important;
	}
}
@supports (overflow:-webkit-marquee) 
   and (justify-content:inherit) {
      .HiddenPageContainer{
		display: block !important;
	}
	.NotCompatible{
		display: none !important;
	}
}*/

.error {
  display: inline-block;
  border: 1px solid #da8486;
  margin: 0px 0px 10px 0px;
  padding: 5px 20px;
  font-size: 1em;
  color: #da8486;
  background-color: #ffdfdf;
}
fieldset {
  border: none;
  .field.checkbox {
    .middleColumn {
      display: inline-block;
    }
  }
}
