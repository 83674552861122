
/* ------------  General Site Responsive ----------- */
@media all and (min-width: 1201px)  {
	#MobileNavigation{
		display: none !important;
	}
}


@media all and (max-width: 786px)  {
	.tacMid{
		text-align: center !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
	#Home5 .title1,
	#Home3{
		font-size: 1em;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
}

@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
/*** Gallery Responsive ***/

/* ------------  General Site Responsive ----------- */
/* ------------  Pages ----------- */
@media all and (max-width: 1200px)  {
	#Home6{
		.Points{
			font-size: 2.5em;
		}
	}
	#Home6 .StatTitle {
	    text-transform: uppercase;
	    font-size: 0.8em;
	}
}
@media all and (max-width: 1308px)  {
	#RecentWork{
		.flex{
			display: block !important;
		}
		.col-10{
			width: 100% !important;
		}
	}

	.PortfolioTitle{
		width: 100% !important;
		clear: both;
		opacity: 1 !important;
		padding: 30px 20px !important;
		@include transition(all 0ms 0ms !important);
		@include transform( translateX(0%) !important);
		.SliderTitle{
			font-size: 1.3em !important;
		}
	}


	#PortfolioButton {
   	 position: absolute;
	    @include transform(translateY(-50%) translateX(0%) !important);
	    right: 20px !important;
	    bottom: auto !important;
	    width: 150px !important;
	    left: auto !important;
	    top: 50% !important;
	}
	
}

@media all and (max-width: 1100px)  {
	.RecentWorkSection{
		width: 33.33%;
	}
}
@media all and (max-width: 810px)  {
	.RecentWorkSection{
		width: 50%;
	}
}
@media all and (max-width: 600px)  {
	.RecentWorkSection{
		width: 100%;
	}
}
@media all and (max-width: 1050px)  {
	.AboutUsContent{
		display: none !important;
	}
	.FundraisingPage .banner .bg, .AboutUsPage .banner .bg {
	    width: 100%;
	}
	.MobileSection{
		display: block !important;
	}
}
@media all and (max-width: 1010px)  {
	.CaseStudySliderImage{
		display: none !important;
	}
	.CaseStudiesContent #SchoolSliderContainer {
	    min-width: 100%;
	    width: 100%;
	}
}
@media all and (max-width: 1152px)  {
	.footerFormContainer,
	.SubscriptionFormTitle{
		width: 100%;
		clear: both !important;
		text-align: center;
	}

	.SubscriptionFormTitle{
	padding-bottom: 20px;
	}
}

@media all and (max-width: 880px)  {
	#FooterTop #Form_SubscriptionForm_FullName, #FooterTop #Form_SubscriptionForm_School, #FooterTop #Form_SubscriptionForm_Email{
		margin-left: 0px;
		margin-bottom: 5px;
		width: 100% !important;
	}
	#FooterTop *{
		width: 100% !important;
		clear: both;
	}
}
@media all and (max-width: 1000px)  {
	.schoolIcon{
		width: 25% !important;
		padding-bottom: 15%;
	}
}
@media all and (max-width: 900px)  {

	.PortfolioTitle{
		@include transition(all 0ms 0ms !important);
		@include transform(translateX(0%) translateY(0%) !important);
		opacity: 1 !important;
	}
	.image{
		@include transition(all 0ms 0ms !important);
		@include transform(translateX(0%) translateY(0%) !important);
		width: 50% !important;
		padding-bottom: 50% !important;
		opacity: 1 !important;
	}
}
@media all and (max-width: 885px)  {
	#Home6{
		.col-3{
			width: 50%;
		}
	}
	
}
@media all and (max-width: 885px)  {
	.schoolIcon{
		width: 33.33% !important;
		padding-bottom: 20%;
	}
	.bannerText h1 {
	    font-size: 2em;
	    font-weight: 400;
	}
	a.bx-pager-link {
    	width: 30px;
    }
    .bx-pager-item {
	    padding: 0px 10px;
	}
}
@media all and (max-width: 580px)  {
	.schoolIcon{
		width: 50% !important;
		padding-bottom: 30%;
	}
}
@media all and (max-width: 680px)  {
	#Footer{
	.SubscriptionFormTitle{
		font-size: 1.3em;
		margin-bottom: 15px;
	}
		.col-7,
		.col-5,
		.col-8,
		.col-4{
			clear: both;
			text-align: center;
			width: 100%;
		}
	}
	#MobileNavigation ul li a {
	    font-size: 1em !important;
	    padding: 3px 34px 2px 34px !important;
	    &:after {
          content: '';
          position: absolute;
          display: block;
          border-right: 1px solid #bdbbbb;
          border-bottom: 1px solid #bdbbbb;
          height: 5px;
          bottom: 0px;
          width: 5px;
          right: 0px;
          opacity: 0;
        }
        &:before {
          content: '';
          position: absolute;
          display: block;
          border-left: 1px solid #bdbbbb;
          border-top: 1px solid #bdbbbb;
          height: 5px;
          top: 0px;
          width: 5px;
          left: 0px;
          opacity: 0;
        }
	}
	#MobileNavigation .toggleNav {
    	top: -2px;
    }
}

@media all and (max-width: 620px)  {
	.topLinks,
	.callus{
		display: none;
	}
	header #Logo {
	    width: 118px;
	}
	.scrollNav #Logo {
	    width: 118px !important;
	}
	header .innerHeader,
	.scrollNav header .innerHeader {
	    min-height: 75px;
	}
	#MenuIcon{
	    top: 10px !important;
	}
	header {
    	padding: 0px 20px;
    }
    .SearchContent {
    padding-top: 80px;
}
}
@media all and (max-width: 560px)  {
	.pageTitle{
		font-size: 1.4em !important;
	}
	.bannerText h1 {
	    font-size: 1.5em;
	    font-weight: 400;
	}
}
@media all and (max-width: 550px)  {
	.PortfolioTitle{
		text-align: center !important;
	}
	.image{
		width: 100% !important;
		padding-bottom: 100% !important;
	}
}
@media all and (max-width: 540px)  {
	#Home6{
		.col-3{
			width: 100%;
		}
	}
}

@media all and (max-width: 440px)  {

	#FooterTop #Form_Form_action_submit {
		width: 100%;
	}
	.footerForm .Actions{
		width: 100%;
	}
	#Form_Form_Email_Holder{
		width: 100%;
	}
	#FooterTop form * {
	    display: block;
	    float: left;
	    width: 100%;
	}
	.footerLogo img {
		width: 50%;
	}
}
/* ------------  Pages ----------- */
/* ------------  Mobile Header ----------- */

@media all and (max-width: 1100px)  {
	#MenuIcon{
		display: block;
	}
	#DesktopNavigation{
		display: none;
	}


}
/* ------------  Mobile Header ----------- */
